import request from "@/api/service";

const api = {
   statistics: '/device-hotel/device/statistics',
   // 同步配置
   synchronizationDevice:'/device-hotel/device/synchronizationDevice/',
}

export function getStatistics(params) {
   return request({
      url: api.statistics,
      method: 'POST',
      data: params
   })
}

export function synchronizationDevice(id) {
   return request({
      url: api.synchronizationDevice + id,
      method: 'get',
   })
}