<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <el-row class="total-wrap" type="flex">
      <dl v-for="(item, index) in parseOnLineTotals" :key="index">
        <dt><el-image :src="require(`@/assets/aiot-index-icon${index + 1}.png`)"></el-image></dt>
        <dd><p>{{ item.name }}</p><span>{{ item.total }}</span></dd>
      </dl>
    </el-row>
    <el-row class="number-wrap" type="flex">
      <dl v-for="(item, index) in parseOnLineNums" :key="index">
        <dt><i class="iconfont" :class="item.class"></i></dt>
        <dd><p>{{ item.name }}</p><span>{{ item.total }}</span></dd>
      </dl>
    </el-row>
    <el-row class="chart-wrap">
      <div class="top">七天内报警统计</div>
      <div class="alarm-chart">
        <div ref="chart" id="chart"></div>
      </div>
    </el-row>
  </div>
</template>
<script>
import { getStatistics } from '@/api/aiot/index';
import { mapState } from 'vuex'
export default {
  name: 'aiotIndex',
  data() {
    return {
      crumbs: new Map([
          ['AIOT'], ['首页']
      ]),
      onLineTotals: {},
    }
  },
  computed: {
    ...mapState(['hotelInfo']),
    parseOnLineTotals() {
      const totalArr = [
        { name: '酒店设备', code: 'total', total: 0 },
        { name: '在线设备', code: 'online', total: 0 },
        { name: '离线设备', code: 'offline', total: 0 },
      ]
      for (const k in this.onLineTotals) {
        let item = totalArr.find(i => i.code === k)
        if (item) item.total = this.onLineTotals[k]
      }
      return totalArr
    },
    parseOnLineNums() {
      const totalArr = [
        { name: '网关', code: 'HOGW', class: 'iconwangguan', total: 0 },
        { name: '开关面板', code: 'HOSW', class: 'iconHotelSwitch', total: 0 },
        { name: '场景面板', code: 'HOSC', class: 'iconchangjingmoshi', total: 0 },
        { name: '插座', code: 'HOSO', class: 'iconWifiSocket', total: 0 },
        { name: '门磁感应器', code: 'HODS', class: 'iconGateMagnetism', total: 0 },
        { name: '烟雾感应器', code: 'HOSA', class: 'iconSmokeDetector', total: 0 },
        { name: '燃气感应器', code: 'HOGS', class: 'iconGasAlarm', total: 0 },
        { name: '红外感应器', code: 'HOMS', class: 'iconInfraredSensor', total: 0 },
        { name: '窗帘导轨', code: 'HORA', class: 'icondaogui', total: 0 },
        { name: '智能锁', code: 'HOSL', class: 'iconDoorLock', total: 0 },
        { name: '红外宝', code: 'HOIN', class: 'iconInfraredTreasure', total: 0 },
        { name: '温控面板', code: 'HOTC', class: 'iconAirConditioningPanel', total: 0 },
        { name: '空调面板', code: 'HAVC', class: 'iconkongtiao', total: 0 }
      ]
      for (const k in this.onLineTotals) {
        let item = totalArr.find(i => i.code === k)
        if (item) item.total = this.onLineTotals[k]
      }
      return totalArr
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => this.getDeviceStatistics())
    })
  },
  methods: {
    getDeviceStatistics() {
      const params = { hotelId: this.hotelInfo.id };
      const option = {
        grid: {
          left: '5%', right: '5%', top: '10%', bottom: '10%'
        },
        legend: { data: ['飞房报警', '离线报警'] },
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' },
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLine: { lineStyle: { color: '#aaaaaa' } },
        },
        yAxis: {
          type: 'value',
          minInterval: 1, // Y轴刻度间距至少为1
          axisLine: { lineStyle: { color: '#aaaaaa' } }
        },
        series: [{
          data: [], type: 'line', name: '飞房报警',
          itemStyle: {
            normal: {
              color: '#2CC9FC',
              lineStyle: { color: '#2CC9FC' }
            }
          }
        },{
          data: [], type: 'line', name: '离线报警',
          itemStyle: {
            normal: {
              color: '#FF4343',
              lineStyle: { color: '#FF4343' }
            }
          }
        }]
      }
      getStatistics(params).then(({ success, records }) => {
        if (success) {
          this.onLineTotals = records.hotel || {}
          const { flyingAlarm, offlineAlarm } = { ...records }
          const alarmArr = [ flyingAlarm, offlineAlarm ]
          for (const [idx, val] of alarmArr.entries()) {
            for (const k in val) {
              idx === 0 && option.xAxis.data.push(k)
              option.series[idx].data.push(val[k])
            }
          }
          this.$echarts.init(this.$refs.chart).setOption(option)
          window.onresize = () => {
            this.$echarts.init(this.$refs.chart).resize()
          }
        }
      })
    },
  },
  watch: {
    hotelInfo(newVal, oldVal) {
      oldVal && newVal.id !== oldVal.id && this.getDeviceStatistics()
    },
  }
}
</script>
<style lang="scss" scoped>
.cont {
  .total-wrap {
    background: #FFFFFF;
    dl {
      display: flex; align-items: center; padding: 30px 0 30px 30px;
      width: 33.33%; position: relative;
      dt {
        margin: 0 20px 0 0;
        .el-image { width: 48px; height: 48px; background-size: 100% 100% }
      }
      dd {
        p { font-size: 14px; color: #999999; margin-bottom: 10px }
        span { font-size: 28px; color: #087FFD }
      }
    }
    dl:before {
      position: absolute; content: ''; width: 1px;
      height: 60px; background-color: #CCCED9; right: 0; top: 30px;
    }
  }
  .number-wrap {
    background: #FFFFFF; margin-top: 15px;
    flex-wrap: wrap;
    dl {
      display: flex; align-items: center; width: 130px; margin: 24px 80px 24px 24px;
      dt {
        margin-right: 8px;
        i { font-size: 24px; color: #087FFD; font-weight: bold }
      }
      dd {
        p { font-size: 14px; color: #B9B9B9; margin-bottom: 5px }
      }
    }
  }
  .chart-wrap {
    margin-top: 15px;
    .top {
      background: #087FFD; line-height: 60px; color: #FFFFFF;
      text-indent: 20px;
    }
    .alarm-chart{
      background: #FFFFFF; padding: 20px 0;
      #chart { width: 100%; height: 360px }
    }
  }
}
</style>
